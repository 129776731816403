import { Tracking } from '@oz/shared/out/interfaces/Tracking';
import { getDevice } from 'modules/applicationConfig';
import { writeToLogConsole } from 'modules/tamperMonkeyAdapter';

import { BASE_URL, SET_CUSTOM_DIM_KEY } from './constants';
import getMatomoData from './getMatomoData';
import PageTrackingContrainer from './PageTrackingContrainer';

export default (trackingModell: Tracking, pagename: string): void => {
    const matomoTrackingContainer = PageTrackingContrainer();
    const { cpid, matomoSiteId, cpref, trackingId } = getMatomoData(trackingModell);
    const deviceoutput = getDevice();

    // Basic stuff
    matomoTrackingContainer.push(['setDocumentTitle', pagename]);

    matomoTrackingContainer.push(['setCustomUrl', document.URL]);

    // custom dimensions

    matomoTrackingContainer.push([SET_CUSTOM_DIM_KEY, 1, deviceoutput]);
    matomoTrackingContainer.push([SET_CUSTOM_DIM_KEY, 5, cpid]);
    matomoTrackingContainer.push([SET_CUSTOM_DIM_KEY, 6, cpref]);
    matomoTrackingContainer.push([SET_CUSTOM_DIM_KEY, 11, trackingId]);
    matomoTrackingContainer.push([SET_CUSTOM_DIM_KEY, 15, 'onlinezulassung']);
    matomoTrackingContainer.push([SET_CUSTOM_DIM_KEY, 17, pagename]);
    matomoTrackingContainer.push([SET_CUSTOM_DIM_KEY, 18, cpid]);
    matomoTrackingContainer.push([SET_CUSTOM_DIM_KEY, 23, trackingId]);

    matomoTrackingContainer.push(['enableLinkTracking']);
    matomoTrackingContainer.push(['enableHeartBeatTimer']);

    matomoTrackingContainer.push(['setTrackerUrl', `${BASE_URL}js/`]);
    matomoTrackingContainer.push(['setSiteId', matomoSiteId]);
    matomoTrackingContainer.push(['trackPageView']);

    // display in greasemonkey log
    writeToLogConsole('matomoPageTracking', matomoTrackingContainer.getCurrentContainer());
};
