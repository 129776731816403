import { DeviceOutput } from '@kfz/web-toolkit/client';

import { ChatbotConfig } from '@oz/shared/out/interfaces/ChatbotConfig';
import { getDevice } from 'modules/applicationConfig';

import { OzRoute } from '../../enums/zulassung/OzRoute';

const chatFlow = {
    name: 'kfz.kfz_flows_first',
    entry: {
        prefix: 'start_oz',
        app: 'app',
        web: 'web',
        hub: 'hub',
        berechnungsStrecke: 'bs',
    },
};

const chatbotWindow = window as unknown as ChatbotWindow;

const getChatFlowEntry = (pathname: string, isNativeApp: boolean) => {
    const platform = isNativeApp ? chatFlow.entry.app : chatFlow.entry.web;
    const context = pathname === OzRoute.HUB ? chatFlow.entry.hub : chatFlow.entry.berechnungsStrecke;
    return `${chatFlow.entry.prefix}_${platform}_${context}`;
};

const getChatbotConfig = (chatFlowEntry: string) => {
    // @ts-ignore
    const { chattyVersion, env, url }: ChatbotConfig = window.KfzZula.chatbotConfig;
    return {
        chattyVersion,
        env,
        onDemand: true,
        type: 'chatty',
        name: `chatty-${chatFlowEntry}`,
        chatbot: {
            active: false,
            deviceoutput: getDevice(),
            client: {
                controlServerAddress: url,
                chatFlowName: chatFlow.name,
                chatFlowRelease: 'active',
                chatFlowEntry,
                clientDetails: {
                    product: 'zula',
                },
            },
            contact: {
                button: {
                    active: false,
                },
                layer: {
                    active: false,
                },
            },
        },
        contact: { layer: {} },
    };
};

const setChatbotConfigForNativeApp = (chatFlowEntry: string) => {
    if (chatbotWindow?.C24App?.setSupportDialogHandler) {
        const setSupportDialogHandlerCompletion = () => {
            if (chatbotWindow?.C24App?.triggerChattyWithOptions) {
                chatbotWindow.C24App.triggerChattyWithOptions({
                    chatFlowName: chatFlow.name,
                    chatFlowEntry,
                    chatFlowRelease: 'active',
                    presentationType: 'sheet',
                    sheetState: 'half',
                });
            }
            return true;
        };
        chatbotWindow.C24App.setSupportDialogHandler(setSupportDialogHandlerCompletion);
    }
};

export const setChatbotConfig = (pathname: string = OzRoute.DATENEINGABE) => {
    const isNativeApp = [DeviceOutput.APP, DeviceOutput.TABLETAPP].includes(getDevice());
    const chatFlowEntry = getChatFlowEntry(pathname, isNativeApp);

    if (chatbotWindow.Check24?.loader?.setContactLayer) {
        chatbotWindow.Check24.loader.setContactLayer(getChatbotConfig(chatFlowEntry), true);
        chatbotWindow.Check24.loader.useChatbotApp = isNativeApp;
    }

    if (isNativeApp) {
        setChatbotConfigForNativeApp(chatFlowEntry);
    }
};
